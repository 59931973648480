import React from "react";
import { graphql } from "gatsby";
import BusinessCase from "./case-page";

const CasePageES = (props) => <BusinessCase {...props} />;

export default CasePageES;

export const caseQuery = graphql`
  query CasePageESByID($id: String!) {
    case: markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        langKey
      }
      frontmatter {
        title
        description
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 400, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        clientlogo {
          childImageSharp {
            fluid(maxWidth: 400, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        logogroups {
          title
          logos {
            name
            url
            logo {
              childImageSharp {
                fluid(maxWidth: 400, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        button_cta
        button_url
      }
    }
  }
`;
